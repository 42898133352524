<template>
  <el-dialog
    class="export-student-dialog"
    title="批量导出学生(带照片)"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="1260px"
    :before-close="close">
    <!-- 打包学生信息 -->
    <export-info-search @refresh="renderTable(1)" />
    <table-view
      :total="total"
      :query-info.sync="queryInfo"
      @reload-table="renderTable"
    >
      <template #header>
        <view-search-form
          ref="searchFormRef"
          :queryInfo.sync="queryInfo"
          :tool-list="['keyword']"
          :filter-keys="['type']"
          @on-search="renderTable(1)" />
      </template>
      <el-button title="刷新" circle style="position:absolute;right:3rem;transform: translateY(-50px)"
                 @click="renderTable(1)"><i
        class="el-icon-refresh"></i></el-button>
      <!--  body  -->
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="50" />
        <el-table-column label="文件名" min-width="120">
          <template v-slot="{row}">
            <template v-if="row.fileUrl">
              <el-tooltip content="点击下载" placement="top">
                <el-button class="download-btn" type="text"
                           @click="$http.exportExcelHttp.exportUserFiles(row.id,row.name)"><i
                  class="el-icon-download"></i>&nbsp;{{ row.fileName }}
                </el-button>
              </el-tooltip>
            </template>
            <span v-else>{{ row.name }} <b>(打包中，打包完成后即可下载...)</b></span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="开始打包时间"  width="160" align="center"/>
        <el-table-column prop="updateTime" label="打包完成时间"  width="160" align="center"/>
        <el-table-column prop="remarks" label="备注" />
        <el-table-column label="操作" width="150" fixed="right">
          <template v-slot="{row}">
            <el-button size="mini" type="danger" @click="del(deleteUserFilesApi, row.id,`文件-${row.name}`)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </table-view>
    <template #footer>
      <el-button @click="close">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { deleteUserFilesApi, pageUserFilesApi } from '@/api/user-files-api'
import tableView from '@/vue/mixins/table-view'
import ExportInfoSearch from '@/views/pages/recruitManage/import-student/component/exportInfoSearch.vue'

export default {
  name: 'exportStudentDialog',
  components: { ExportInfoSearch },
  mixins: [dialog, tableView],
  data () {
    return {
      queryInfo: {
        type: 'stu'
      }
    }
  },
  methods: {
    deleteUserFilesApi,
    async renderTable (pageNum) {
      await this.getTableData(pageUserFilesApi, pageNum)
    }
  }
}
</script>
<style lang="scss">
.export-student-dialog {
  .el-dialog {
    margin-top: 20px !important;
  }

  .download-btn {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
