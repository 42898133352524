import request from '@/service/request'

// 获取 导出学生列表 page
export const pageUserFilesApi = (params) => {
  return request.post('/sys/asyncExcelFile/page', params)
}

// 获取 导出学生列表 list
export const listUserFilesApi = () => {
  return request.post('/sys/asyncExcelFile/list')
}

// 根据id获取 导出学生列表
export const getUserFilesByIdApi = (id) => {
  return request.post(`/sys/asyncExcelFile/getById/${id}`)
}

// 添加学生导出
export const saveUserFilesApi = (params) => {
  return request.post('/sys/asyncExcelFile/save', params)
}

// 删除学生导出
export const deleteUserFilesApi = (id) => {
  return request.post(`/sys/asyncExcelFile/del/${id}`)
}

// 打包学生信息 打包后才能下载
export const bundleStudentFilesApi = (params) => {
  return request.post('/stu/stuInfo/exportHeadshotInfo', params)
}

// 打包学系统用户信息 打包后才能下载
export const bundleUserFilesApi = (params) => {
  return request.post('/sys/user/exportHeadshotInfo', params)
}
