var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"queryInfo":_vm.queryInfo,"tool-list":['grade',  'college', 'major', 'stuStatus','keyword'],"insert-select-all":[ 'college', 'major']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"学制","label-width":"60px"}},[_c('el-select',{attrs:{"clearable":"","size":"small"},model:{value:(_vm.queryInfo.lengthOfSchooling),callback:function ($$v) {_vm.$set(_vm.queryInfo, "lengthOfSchooling", $$v)},expression:"queryInfo.lengthOfSchooling"}},_vm._l((_vm.$store.state.systemParam.lengthOfSchoolingType),function(ref){
var paramName = ref.paramName;
var paramValue = ref.paramValue;
return _c('el-option',{key:paramValue,attrs:{"label":paramName,"value":Number(paramValue)}})}),1)],1)],1),_c('div',{staticClass:"header-button"},[_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function (){ return _vm.visibleDIYExportDialog=true; }}},[_vm._v("导出该筛选条件学生")]),_c('el-button',{attrs:{"type":"success","plain":"","size":"small","piain":""},on:{"click":function($event){_vm.visibleExportDialog=true}}},[_vm._v("批量导出(带照片) ")]),_c('upload-excel',{attrs:{"permit-key":['stu:import:edit'],"loading":_vm.loading,"api":_vm.$http.importExcelHttp.importStudent},on:{"update:loading":function($event){_vm.loading=$event},"on-download":function($event){return _vm.$http.exportExcelHttp.exportStudentTemplate()},"on-success":function($event){return _vm.renderTable(1)}}}),(_vm.canBatchSaveNoPay)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:import:edit']),expression:"['stu:import:edit']"}],attrs:{"type":"danger","size":"small","disabled":!_vm.stuInfo.length},on:{"click":_vm.changeIntoNotReport}},[_vm._v("转入未缴费名单 ")]):_vm._e(),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:import:edit']),expression:"['stu:import:edit']"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("添加学生 ")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""},on:{"selection-change":_vm.selectStudent}},[(_vm.canBatchSaveNoPay)?_c('el-table-column',{attrs:{"type":"selection","width":"55"}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"120"}}),_c('el-table-column',{attrs:{"prop":"stuNo","label":"学号","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuNo || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"grade","label":"年级","width":"80"}}),_c('el-table-column',{attrs:{"label":"姓名","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showSubDialog('visibleOnlyReadDialog','onlyReadDialogRef',row.id,row)}}},[_vm._v(" "+_vm._s(row.stuName)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"学籍状态","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.stuStatus, _vm.$store.state.systemParam.stuStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sexType","label":"性别","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [(Number(scoped.row.sexType) === 1)?_c('span',[_vm._v("男")]):_c('span',[_vm._v("女")])]}}])}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"院系","width":"150"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","width":"150"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className || '未绑定')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"campusName","label":"校区名称","width":"100"}}),_c('el-table-column',{attrs:{"prop":"apartmentName","label":"宿舍","width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName && row.dormitoryBedNo)?_c('span',[_vm._v(_vm._s(row.dormitoryName)+_vm._s(row.roomName)+"室"+_vm._s(row.dormitoryBedNo)+"号床")]):_c('span',[_vm._v("未分配")])]}}])}),_c('el-table-column',{attrs:{"label":"门禁照片","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.headshot)?[_c('el-popover',{attrs:{"placement":"top","width":"189","title":("门禁照片：" + (row.stuName)),"lazy":"","trigger":"hover"}},[_c('el-image',{staticStyle:{"width":"179px","height":"auto"},attrs:{"preview-src-list":[_vm.$fileUrl + row.headshot],"src":_vm.$fileUrl + row.headshot,"fit":"contain"}}),_c('el-button',{attrs:{"slot":"reference","type":"text","size":"mini"},slot:"reference"},[_c('i',{staticClass:"el-icon-more"})])],1)]:_c('span',{staticClass:"font-grey"},[_vm._v("--无--")])]}}])}),_c('el-table-column',{attrs:{"label":"免缴费登录","width":"130","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:import:edit']),expression:"['stu:import:edit']"}],attrs:{"active-color":"var(--color-success)","inactive-color":"#d0d6e8","active-value":1,"active-text":"是","inactive-value":0,"inactive-text":"否"},on:{"change":function($event){return _vm.handleSwitch(row)}},model:{value:(row.payPassFlag),callback:function ($$v) {_vm.$set(row, "payPassFlag", $$v)},expression:"row.payPassFlag"}})]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注"}}),_c('el-table-column',{attrs:{"label":"操作","width":"150","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:import:edit']),expression:"['stu:import:edit']"}],attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id,("-" + (row.stuName)))}}},[_vm._v("编辑 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:import:del']),expression:"['stu:import:del']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.deleteStudent, row.id,("学生-" + (row.stuName)))}}},[_vm._v("删除 ")])]}}])})],1),(_vm.visibleDialog)?_c('student-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleOnlyReadDialog)?_c('student-only-read-dialog',{ref:"onlyReadDialogRef",attrs:{"visible":_vm.visibleOnlyReadDialog},on:{"update:visible":function($event){_vm.visibleOnlyReadDialog=$event}}}):_vm._e(),(_vm.visibleExportDialog)?_c('export-student-dialog',{ref:"exportStudentDialogRef",attrs:{"visible":_vm.visibleExportDialog},on:{"update:visible":function($event){_vm.visibleExportDialog=$event}}}):_vm._e(),(_vm.visibleDIYExportDialog)?_c('DialogExportStuSelect',{attrs:{"visible":_vm.visibleDIYExportDialog,"query-info":_vm.queryInfo},on:{"update:visible":function($event){_vm.visibleDIYExportDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }