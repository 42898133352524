<template>
  <el-collapse
    class="export-info-search mb-1" v-model="activeTab">
    <el-collapse-item title="打包参数设置" name="1">
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade',  'college', 'major', 'stuStatus']"
        :insert-select-all="[ 'college', 'major']">
        <el-form-item label="学制" label-width="60px">
          <el-select v-model="queryInfo.lengthOfSchooling" clearable size="small">
            <el-option
              v-for="{paramName,paramValue} in $store.state.systemParam.lengthOfSchoolingType"
              :key="paramValue"
              :label="paramName"
              :value="Number(paramValue)" />
          </el-select>
        </el-form-item>
        <el-form-item label="打包后的文件名" label-width="120px">
          <el-input v-model="queryInfo.name" size="small" />
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-button type="success" plain @click="bundle">开始打包</el-button>
          </el-col>
        </el-row>
      </view-search-form>
    </el-collapse-item>
  </el-collapse>

</template>

<script>
import { bundleStudentFilesApi } from '@/api/user-files-api'

export default {
  name: 'exportInfoSearch',
  props: {
    // 用来阻止初始化时 on-search 默认执行
    updateFlag: Boolean
  },
  data () {
    return {
      activeTab: '1',
      queryInfo: {
        lengthOfSchooling: null, // 学制
        stuStatus: 0, // （0——未注册，1——在读，2——毕业）
        campusId: null,
        grade: new Date().getFullYear(),
        collegeId: null,
        majorId: null,
        classId: null,
        sexType: null,
        name: ''
      }
    }
  },
  methods: {
    bundle () {
      // if (!this.queryInfo.name) {
      //   this.$message.error('请输入打包后的文件名!')
      //   return
      // }
      this.$confirm('是否确认打包？', '确认').then(async () => {
        try {
          await bundleStudentFilesApi(this.queryInfo)
          this.$confirm('打包流程已启动，请耐心等待，当下面表格中文件名变蓝色时，即可下载。', '确认', {
            type: 'success'
          }).then(() => {
            this.$emit('refresh')
          })
        } catch (e) {
        }
      }).catch(() => {
        this.$message('已取消打包')
      })
    }
  }
}
</script>

<style lang="scss">
.export-info-search {
  border: 0;
  padding: 2px 12px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px #EBEEF5 inset;
  box-sizing: border-box;

  .el-collapse-item__header {
    font-size: 16px;
    font-weight: 600;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-form-item {
    .el-button {
      &.el-button--small {
        display: none;
      }
    }
  }
}
</style>
